<template>
  <!--  <el-dialog-->
  <!--      title="下载档案"-->
  <!--      :visible.sync="dialogVisible"-->
  <!--      top="4%"-->
  <!--      width="80%"-->
  <!--      :center="true"-->
  <!--      :modal="modal"-->
  <!--      @closed="dialogClosed"-->
  <!--  >-->
  <div class="pageContol listWrap templateList dialogPaper">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()">报名资料下载</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">下载档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc operationControlReset" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="招生任务名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 100px!important;;width: 100px!important;">招生任务名称：</span>
              <span style="text-align:left">{{ row.projectName }}</span>
            </div>
            <div title="招生起止时间" class="searchboxItem ci-full">
              <span class="itemLabel">招生起止时间：</span>
              <span style="text-align:left">{{ row.startDate }}至{{ row.endDate }}</span>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="报名时间" class="searchboxItem ci-full" style="flex:2">
              <span class="itemLabel" style="min-width: 80px!important;;width: 80px!important;">报名时间：</span>
              <el-date-picker v-model="time" type="daterange" align="right" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy/MM/dd" size="small"></el-date-picker>
            </div>
            <div title="审核状态" class="searchboxItem ci-full" style="flex:1.5">
              <span class="itemLabel" style="min-width: 80px!important;;width: 80px!important;">审核状态：</span>
              <el-select size="small" v-model="auditState" clearable placeholder="请选择">
                <el-option v-for="item in auditStatusList" :key="item.auditState" :label="item.label"
                  :value="item.auditState">
                </el-option>
              </el-select>
            </div>
            <div title="渠道联系人" class="searchboxItem ci-full" style="flex:1.5">
              <span class="itemLabel" style="min-width: 80px!important;;width: 80px!important;">招生渠道：</span>
              <el-select size="small" v-model="channelId" clearable placeholder="请选择">
                <el-option v-for="item in channelList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="downloadFile('2')">下载</el-button>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="downloadFile('3')">下载全部</el-button>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
            <div title="材料名称" class="searchboxItem ci-full">
              <span class="itemLabel">材料名称：</span>
              <el-checkbox-group v-model="attributeIds" :min="1" @change="checkChange">
                <el-checkbox v-for="item in infoList" :key="item.attributeId" :label="item.attributeId">{{
                  item.attributeLabel }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
              <div title="下载内容" class="searchboxItem ci-full">
                  <span class="itemLabel">下载内容：</span>
                  <el-radio-group v-model="filePattern">
                      <el-radio label="10">图片+学员信息汇总表</el-radio>
                      <el-radio label="20">仅下载学员信息汇总表</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
              <div title="行政区划按省市县导出" class="searchboxItem ci-full">
                  <span class="itemLabel">行政区划按省市县导出：</span>
                  <el-radio-group v-model="isAreaPart">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false" style="margin-left: 10px;">否</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div class="searchbox">
              <div title="存放方式" class="searchboxItem ci-full">
                  <span class="itemLabel">存放方式：</span>
                  <el-radio-group v-model="merge">
                      <el-radio :label="false">每个人一个文件夹</el-radio>
                      <el-radio :label="true">所有人在一个文件夹</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div class="searchbox" v-if="!merge">
            <div title="存放方式" class="searchboxItem ci-full">
              <span class="itemLabel">文件夹命名方式：</span>
              <el-checkbox-group v-model="dirNameMethods">
                <el-checkbox label="10">姓名</el-checkbox>
                <el-checkbox label="20">身份证号</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="searchbox">
            <div title="文件命名方式" class="searchboxItem ci-full">
              <span class="itemLabel">文件命名方式：</span>
              <el-checkbox-group v-model="fileNameMethods" :min="1">
                <el-checkbox label="10">姓名</el-checkbox>
                <el-checkbox label="20">身份证号</el-checkbox>
                <!--<el-checkbox label="30" title="当下载内容大于一项时，此项必须选中" :disabled="attributeIds.length > 1">证件名称</el-checkbox>-->
              </el-checkbox-group>
            </div>
          </div>
          <div class="searchbox">
              <div title="文件命名间隔符号" class="searchboxItem ci-full">
                  <span class="itemLabel">文件命名间隔符号：</span>
                  <el-radio-group v-model="fileNameSymbol">
                      <el-radio label="10">下划线（ _ ）</el-radio>
                      <el-radio label="20">中线（ - ）</el-radio>
                      <el-radio label="30">无</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
              <div title="文件名增加后缀" class="searchboxItem ci-full">
                  <span class="itemLabel">文件名增加后缀：</span>
                  <el-radio-group v-model="fileNameSuffix">
                      <!--<el-radio label="10">证件名称</el-radio>-->
                      <el-radio label="20">自定义</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <template v-if="fileNameSuffix == '20'">
              <div class="searchbox" style="margin-bottom:15px" v-for="(item,index) in fileNameSuffixes" :key="index">
                  <div title="白底小二寸免冠照片" class="searchboxItem spInput ci-full">
                      <span class="itemLabel">{{ item.suffixLabel }}：</span>
                      <el-input v-model="item.suffixName" placeholder="请输入" type="text" clearable size="small" maxlength="20" show-word-limit/>
                      <span class="spInputEx">
                          示例：
                          {{ fileNameMethods.filter(item=>item=='10').length>0?'李某某':''}}
                          {{ fileNameSymbol == '10'&&fileNameMethods.length==2?'_':fileNameSymbol == '20'&&fileNameMethods.length==2?'-':'' }}
                          {{ fileNameMethods.filter(item=>item=='20').length>0?'130323********1455':''}}
                          {{ fileNameSymbol == '10'&&item.suffixName?'_':fileNameSymbol == '20'&&item.suffixName?'-':'' }}
                          {{ item.suffixName || '' }}
                      </span>
                  </div>
              </div>
              <!--<div class="searchbox" style="margin-bottom:15px">-->

              <!--    <div title="身份证人像面" class="searchboxItem ci-full">-->
              <!--        <span class="itemLabel">身份证人像面：</span>-->
              <!--        <el-input v-model="customizationForm.value2" placeholder="请输入" type="text" clearable size="small"/>-->
              <!--        <span style="min-width: 290px!important;text-align:left;">-->
              <!--            示例：-->
              <!--            {{ fileNameMethods.filter(item=>item=='10').length>0?'李某某':''}}-->
              <!--            {{ fileNameSymbol == '10'&&fileNameMethods.length==2?'_':fileNameSymbol == '20'&&fileNameMethods.length==2?'-':'' }}-->
              <!--            {{ fileNameMethods.filter(item=>item=='20').length>0?'130323********1455':''}}-->
              <!--            {{ fileNameSymbol == '10'&&customizationForm.value4?'_':fileNameSymbol == '20'&&customizationForm.value4?'-':'' }}-->
              <!--            {{ customizationForm.value2 || '' }}-->
              <!--        </span>-->
              <!--    </div>-->
              <!--</div>-->
              <!--<div class="searchbox" style="margin-bottom:15px">-->
              <!--    <div title="身份证国徽面" class="searchboxItem ci-full">-->
              <!--        <span class="itemLabel">身份证国徽面：</span>-->
              <!--        <el-input v-model="customizationForm.value3" placeholder="请输入" type="text" clearable size="small"/>-->
              <!--        <span style="min-width: 290px!important;text-align:left;">-->
              <!--            示例：-->
              <!--            {{fileNameMethods.filter(item=>item=='10').length>0?'李某某':''}}-->
              <!--            {{ fileNameSymbol == '10'&&fileNameMethods.length==2?'_':fileNameSymbol == '20'&&fileNameMethods.length==2?'-':'' }}-->
              <!--            {{fileNameMethods.filter(item=>item=='20').length>0?'130323********1455':''}}-->
              <!--            {{ fileNameSymbol == '10'&& customizationForm.value3?'_':fileNameSymbol == '20'&& customizationForm.value3?'-':'' }}-->
              <!--            {{ customizationForm.value3 || '' }}-->
              <!--        </span>-->
              <!--    </div>-->
              <!--</div>-->
              <!--<div class="searchbox" style="margin-bottom:15px">-->
              <!--    <div title="学历证明" class="searchboxItem ci-full">-->
              <!--        <span class="itemLabel">学历证明：</span>-->
              <!--        <el-input v-model="customizationForm.value4" placeholder="请输入" type="text" clearable size="small"/>-->
              <!--        <span style="min-width: 290px!important;text-align:left;">-->
              <!--            示例：-->
              <!--            {{fileNameMethods.filter(item=>item=='10').length>0?'李某某':''}}-->
              <!--            {{ fileNameSymbol == '10'&&fileNameMethods.length == 2?'_':fileNameSymbol == '20'&&fileNameMethods.length == 2?'-':'' }}-->
              <!--            {{ fileNameMethods.filter(item=>item=='20').length>0?'130323********1455':''}}-->
              <!--            {{ fileNameSymbol == '10'&&customizationForm.value4?'_':fileNameSymbol == '20'&&customizationForm.value4?'-':'' }}-->
              <!--            {{ customizationForm.value4 || '' }}-->
              <!--        </span>-->
              <!--    </div>-->
              <!--</div>-->
          </template>


        </div>
        <div class="framePage-scroll" style="overflow: visible">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" size="small" tooltip-effect="dark" style="width: 100%;min-height: 500px"
              :header-cell-style="tableHeader" stripe row-key="userId" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" :reserve-selection="true" />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50px" />
              <el-table-column label="姓名" prop="userName" show-overflow-tooltip min-width="160" />
              <el-table-column label="手机号" show-overflow-tooltip prop="mobile" min-width="160" />
              <el-table-column label="审核状态" prop="auditState" show-overflow-tooltip min-width="160">
                <template v-slot="{ row }">
                  {{ $setDictionary("CT_USER_AUDIT_STATE", row.auditState) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="downloadFile('1', scope.row.userId)">下载</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
  <!--  </el-dialog>-->
</template>
<script>

import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "downloadFile",
  mixins: [List],
  components: {
    Empty,
    PageNum
  },
  props: {
    // modal: {
    //   default: function() {
    //     return false;
    //   }
    // }
  },
  data() {
    return {
      // dialogVisible: true,
      row: {}, //父组件传参
      time: '', //报名时间
      channelId: null, //渠道联系人id
      attributeIds: [], // 下载内容
      fileNameMethods: [], // 文件命名方式
      dirNameMethods: [], // 文件夹命名方式
      fileNameSymbol: '10',// 文件命名间隔符号
      fileNameSuffix: '20',// 文件命增加后缀
      // 文件命增加后缀 - 自定义
      fileNameSuffixes:[],

      userIds: [], // 已勾选学员
      infoList: [],
      channelList: [],
      ruleForms: {},
      // 审核状态下拉框数据
      auditStatusList: [
        {
          label: "待审核",
          auditState: "20"
        },
        {
          label: "已通过",
          auditState: "30"
        }
      ],
      // 审核状态
      auditState: "",
      isFirst: true,
      merge: false,// 存放方式
      isAreaPart: true,// 行政区划分别按省市县导出，默认勾选此选项
      filePattern:'10' //勾选文件下载格式（10：下载材料，20：下载excel）

    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 资源提供者1
    getChannelList() {
      this.$post("/biz/ct/channel/ctChannelSelectList", {}).then(res => {
        this.channelList = res.data || [];
        this.channelList.unshift({
          contactUser: "自招",
          channelId: "niu_bi"
        });
      });
    },
    // showPopUp(row) {
    //   this.dialogVisible = true;
    //   this.fileNameMethods = ['10','20','30'];
    //   this.row = row;
    //   this.time = [row.startDate,row.endDate];
    //   this.getData();
    //   this.getDownloadInfo();
    // },
    //
    getData(pageNum = 1) {
      if (this.isFirst) {
        this.time = [this.$route.query.startDate, this.$route.query.endDate];
        this.isFirst = false
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
        projectName: this.$route.query.projectName
      };

      if (this.auditState) {
        params.auditState = this.auditState;
      }
      if (this.time && this.time.length > 0) {
        params.joinDateFront = this.time[0]
        params.joinDateBack = this.time[1]
      }
      if (this.channelId) {
        params.channelId = this.channelId;
      }
      // 这个渠道联系人改成下拉，增加一个自招的下拉选项，这里需要特殊处理一下，选择自招时，不用传channelId，而是需要传confessMark为10
      if (this.channelId === "niu_bi") {
        params.confessMark = "10";
        delete params.channelId;
      }
      this.doFetch({
        url: "/biz/ct/downLoad/ctDownLoadProjectUserList",
        params,
        pageNum
      });
    },
    getDownloadInfo() {
      const params = {
        occupationId: this.row.occupationId,
        projectId: this.row.projectId
      }
      this.$post("/biz/ct/downLoad/ctProjectOccupationAttributeList", params)
        .then((res) => {
          if (res.status == "0") {
            this.infoList = res.data
            this.attributeIds = Array.from(this.infoList, ({ attributeId }) => attributeId)
            this.checkChange()
          }
        });
    },
    // 下载内容改变
    checkChange() {
        let { attributeIds,infoList } = this;
        let fileNameSuffixesList = [];
        attributeIds.forEach((e,i)=>{
            // console.log(infoList.find(item=>item.attributeId == e))
            let currenntId = infoList.find(item=>item.attributeId == e)
            if(currenntId.attributeId == '-1'){
                fileNameSuffixesList.push({
                    attributeId: currenntId.attributeId,
                    attributeType: '10',
                    suffixName:'身份证人像面',
                    suffixLabel:'身份证人像面',
                })
                fileNameSuffixesList.push({
                    attributeId: currenntId.attributeId,
                    attributeType: '20',
                    suffixName:'身份证国徽面',
                    suffixLabel:'身份证国徽面',
                })
            }else{
                fileNameSuffixesList.push({
                    attributeId: currenntId.attributeId,
                    suffixName: currenntId.attributeLabel,
                    suffixLabel: currenntId.attributeLabel,
                })
            }


        })
        this.fileNameSuffixes = fileNameSuffixesList
        console.log(this.fileNameSuffixes)
    },
    // 对话框关闭
    // dialogClosed(){
    //     this.auditState="";
    //     this.$refs.multipleTable.clearSelection();
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 对话框关闭
    // dialogClosed(){
    //     this.auditState="";
    //     this.$refs.multipleTable.clearSelection();
    // },
    goHerf() {
      this.$router.back();
    },
    // 下载
    downloadFile(type, id) {
      // type 1 列表内单条下载  2 查询按钮旁下载  3 查询按钮旁下载全部
      if (!this.attributeIds.length) {
        this.$message.error('请至少勾选一项下载内容！')
        return
      }
      if (!this.fileNameMethods.length) {
        this.$message.error('请至少勾选一项文件命名方式！')
        return
      }
      if (!this.merge && !this.dirNameMethods.length) {
        this.$message.error('请至少勾选一项文件夹命名方式！')
        return
      }
      let params = {
        projectId: this.row.projectId,
        attributeIds: this.attributeIds,
        fileNameMethods: this.fileNameMethods,
        dirNameMethods: this.dirNameMethods,
        userIds: [],
        merge: this.merge,
        isAreaPart:this.isAreaPart,
        filePattern:this.filePattern,
        fileNameSymbol: this.fileNameSymbol,
        fileNameSuffix: this.fileNameSuffix
      }

      if (type == 1) {
        params.userIds.push(id)
      }
      if (type == 2) {
        // 存放方式：所有人在一个文件夹
        // if(this.merge){
        //   // 判断 下载内容 和 文件命名方式 身份证必选
        //   if(!this.fileNameMethods.includes("20")){
        //     this.$message.error('存放方式：所有人在一个文件夹时,文件命名方式 身份证必选！')
        //     return;
        //   }
        // }
        if (!this.multipleSelection || this.multipleSelection.length < 1) {
          this.$message.error('请至少勾选一项列表数据！')
          return
        } else {
          params.userIds = Array.from(this.multipleSelection, ({ userId }) => userId)
        }
      }
      if (type == 3) {
        // 存放方式：所有人在一个文件夹
        // if(this.merge){
        //   // 判断 下载内容 和 文件命名方式 身份证必选
        //   if(!this.fileNameMethods.includes("20")){
        //     this.$message.error('存放方式：所有人在一个文件夹时，文件命名方式 身份证必选！')
        //     return;
        //   }
        // }
        params.userIds = null
      }
      // 如果文件命名增加后缀 勾选了 自定义
      if(this.fileNameSuffix == '20'){
        params.fileNameSuffixes = this.fileNameSuffixes;
          // try{
          //     let hasfalse = false
          //     this.fileNameSuffixes.forEach(item => {
          //         if(!item.suffixName){
          //             hasfalse = true
          //             throw new Error();
          //
          //         }
          //     });
          //     // 如果没有报错
          //     if(!hasfalse){
          //         params.fileNameSuffixes = this.fileNameSuffixes
          //     }
          // }
          // catch(error){
          //     this.$message.error('请补全自定义的文件命名后缀！')
          //     return;
          // };
      }
      this.$alert('下载的信息中包含身份证、照片等敏感信息，下载的信息仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。', '提示', {
        confirmButtonText: '我已阅读并同意',
        callback: action => {
          if (action == 'confirm') {
            this.$post("/biz/ct/downLoad/ctDownLoadUserMaterial", params)
              .then((res) => {
                if (res.status == "0") {
                  for (let item of res.data) {
                    if (!this.downloadItems.includes(item.taskId)) {
                      this.$store.dispatch("pushDownloadItems", item.taskId);
                    } else {
                      this.$message.warning(
                        "[" + item.fileName + "]已经申请下载,请耐心等待"
                      );
                    }
                  }
                  this.closeDocsDialog();
                } else {
                  this.$message.error(res.message);
                }
              })
              .catch((err) => {
                return;
              });
          }
        }
      });
    }
  },
  created() {
    this.getChannelList();
    this.row = this.$route.query;
    this.fileNameMethods = ['10', '20'];
    this.dirNameMethods = ['10', '20'];
    this.getData();
    this.getDownloadInfo();
  }
};
</script>
<!--<style lang="less" scoped>-->
<!--.dialogPaper {-->
<!--  .el-form {-->
<!--    display:flex;-->
<!--    .el-form-item {-->
<!--      display:flex-->
<!--    }-->
<!--  }-->

<!--}-->

<!--</style>-->
<style lang="less" scoped>





.itemLabel {
  padding: 8px 0;
  line-height: 16px;
  min-width: 160px !important;
  width: 160px !important;
}

.el-checkbox-group {
  position: relative;
  top: 3px;
}

.operationControlReset > div {
  line-height: 32px;
}
/deep/.operationControlReset  .el-checkbox__inner {
  min-width: 15px;
  text-align: right;
  font-size: 14px;
  padding: 0;
  border-color: #5c6be8;
  //background-color: #5c6be8;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #5c6be8;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
  background-color: #5c6be8;
  border-color: #5c6be8;
}
/deep/.el-table__empty-block{
  min-height: 500px;
}
.searchbox{
  width: 100%;
  justify-content: flex-start;
}
.spInput{
  /deep/.el-input{
    width: 260px;
  }
  /deep/.el-input__suffix-inner{
    width: auto;
    min-width: unset;
  }
  /deep/.el-input__count{
    width: auto;
    min-width: unset;
    .el-input__count-inner{
      width: auto;
      min-width: unset;
    }
  }
  .spInputEx{
    min-width: 400px;
    text-align: left;
  }
}
</style>
